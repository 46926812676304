import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { format } from "date-fns";
import { it, enUS } from "date-fns/locale"; // Import locales

// Styles import
import "./css/all.css";
import "./css/animate.css";
import "./css/animate.min.css";
import "./css/bootstrap.css";
import "./css/bootstrap.min.css";
import "./css/carousel.css";
import "./css/colors.css";
import "./css/lightbox.css";
import "./css/my.css";
import "./css/responsive.css";
import "./css/style.css";

// Data import
import navData from "./data/nav.json";
import sponsorData from "./data/sponsor.json";
import concertData from "./data/concerts/2024.json";
import newsData from "./data/news.json";
import cdsData from "./data/cds.json";
import testimonialsData from "./data/testimonials.json";

// Components import
import Navbar from "./components/nav/Navbar";
import Home from "./components/home/Home";
import Footer from "./components/Footer";
import Contacts from "./components/Contacts";
import NotFound from "./components/NotFound";
import ConditionalWelcome from "./components/welcome/ConditionalWelcome";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Support from "./components/Support";
import Transparency from "./components/transparency/Transparency";
import Concerts from "./components/concerts/Concerts";
import ConcertDetails from "./components/concerts/ConcertDetails";
import Archive from "./components/archive/Archive";
import Curriculum from "./components/Curriculum";
import About from "./components/About";
import Conductor from "./components/Conductor";
import Soloists from "./components/Soloists";
import Dedications from "./components/Dedications";
import Reviews from "./components/Reviews";
import News from "./components/news/News";
import NewsDetails from "./components/news/NewsDetails";
import Discography from "./components/discography/Discography";
import MagazinesBooks from "./components/magazines/MagazinesBooks";
import Testimonials from "./components/testimonials/Testimonials";
import FirstExecutions from "./components/first/FirstExecutions";

function App() {
  const languages = [
    {
      code: "it",
      name: "Italiano",
      country_code: "it",
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
  ];

  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t("app_title");
  }, [t]);

  // string manipulation for \n in json file
  const renderBody = (body) => {
    return body.split(/\n+/).map((line, index) => <p key={index}>{line}</p>);
  };

  const formatDate = (dateString, lang) => {
    const date = new Date(dateString);
    const locale = lang === "en" ? enUS : it; // Select locale based on language
    return format(date, "dd MMMM yyyy", { locale }); // Example format
  };

  // TODO: Implement this function to use the title as key and not the id (useful in news, concert details etc)
  // converts a string from: "Something like that" to "something-like-that"
  // const slugify = (title) => {
  //   return title
  //     .normalize('NFD') // decompose accented characters
  //   .replace(/[\u0300-\u036f]/g, '') // remove diacritical marks
  //   .toLowerCase() // convert to lowercase
  //   .trim() //  trim leading and trailing whitespace
  //   .replace(/[^a-z0-9\s-]/g, '') // remove non-alphanumeric characters except spaces and hyphens
  //   .replace(/\s+/g, '-') // replace spaces with hyphens
  //   .replace(/-+/g, '-'); // replace multiple hyphens with a single hyphen
  // };

  return (
    <Router>
      <div className="App">
        <Navbar languages={languages} data={navData} />
        <div className="content">
          <ConditionalWelcome />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Home
                  concerts={concertData}
                  news={newsData}
                  cds={cdsData}
                  testimonials={testimonialsData}
                  formatDate={(dateString) =>
                    formatDate(dateString, i18n.language)
                  }
                />
              }
            />
            <Route
              path="/concerts"
              element={<Concerts concerts={concertData} />}
            />
            <Route
              path="/concerts/:id"
              element={
                <ConcertDetails
                  renderBody={renderBody}
                  formatDate={(dateString) =>
                    formatDate(dateString, i18n.language)
                  }
                />
              }
            ></Route>
            <Route
              path="/discography"
              element={<Discography cds={cdsData} renderBody={renderBody} />}
            />
            <Route
              path="/magazines"
              element={<MagazinesBooks renderBody={renderBody} />}
            />
            <Route
              path="/first_executions"
              element={<FirstExecutions renderBody={renderBody} />}
            />
            <Route
              path="/testimonials"
              element={<Testimonials testimonials={testimonialsData} />}
            />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/soloists" element={<Soloists />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route
              path="/news"
              element={
                <News
                  news={newsData}
                  formatDate={(dateString) =>
                    formatDate(dateString, i18n.language)
                  }
                />
              }
            />
            <Route
              path="/news/:id"
              element={
                <NewsDetails
                  formatDate={(dateString) =>
                    formatDate(dateString, i18n.language)
                  }
                  renderBody={renderBody}
                />
              }
            />
            <Route path="/dedications" element={<Dedications />} />
            <Route
              path="/conductor"
              element={<Conductor renderBody={renderBody} />}
            />
            <Route
              path="/curriculum"
              element={<Curriculum renderBody={renderBody} />}
            />
            <Route path="/about" element={<About renderBody={renderBody} />} />
            <Route
              path="/archive"
              element={
                <Archive
                  renderBody={renderBody}
                  formatDate={(dateString) =>
                    formatDate(dateString, i18n.language)
                  }
                />
              }
            />
            <Route
              path="/transparency"
              element={
                <Transparency formatDate={formatDate} renderBody={renderBody} />
              }
            />
            <Route
              path="/support"
              element={
                <Support renderBody={renderBody} sponsors={sponsorData} />
              }
            />
            <Route
              path="/privacy"
              element={<PrivacyPolicy renderBody={renderBody} />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer data={navData} />
      </div>
    </Router>
  );
}

export default App;
