import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

const Welcome = ({ sectionRef }) => {
  const { t } = useTranslation();

  return (
    <section className="hero welcome">
      <div className="video-background">
        <video
          src="/vid/bcg_vid_comp2.mov"
          autoPlay
          muted
          loop
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div className="video-overlay"></div>
      <div className="container">
        <div className="hero-desc">
          <div className="row">
            <div className="col-md-12">
              <h2 className="uppercasebold1">
                nuova orchestra da camera ferruccio busoni
              </h2>
              <a
                className="btn btn-danger upcevents"
                href="https://www.youtube.com/watch?v=t8QaZusfZXU"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faVideo} /> {t("home.next_app")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
