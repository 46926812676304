import ConcertCard from "./ConcertCard";

const ConcertCards = ({ concerts }) => (
  <>
    {concerts.map((concert) => (
      <ConcertCard concert={concert} key={concert.id} />
    ))}
  </>
);

export default ConcertCards;